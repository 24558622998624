import { Category } from "constants/events";
import { AdMarqueePageLocation } from "custom-types/AdMarquee";

const brandAffiliateCategories = {
  [AdMarqueePageLocation.HOME_PAGE]: Category.brandAffiliateAd,
  [AdMarqueePageLocation.STRAINS_LIST_PAGE]:
    Category.strainListBrandAffiliateAd,
  [AdMarqueePageLocation.DEALS_PAGE]: Category.dealsPageListBrandAffiliateAd,
};

const houseCategories = {
  [AdMarqueePageLocation.HOME_PAGE]: Category.homepageHouseAd,
  [AdMarqueePageLocation.STRAINS_LIST_PAGE]: Category.strainListHouseAd,
  [AdMarqueePageLocation.DEALS_PAGE]: Category.dealsPageListHouseAd,
};

const paidCategories = {
  [AdMarqueePageLocation.HOME_PAGE]: Category.homepagePaidAd,
  [AdMarqueePageLocation.STRAINS_LIST_PAGE]: Category.strainListPaidAd,
  [AdMarqueePageLocation.DEALS_PAGE]: Category.dealsPageListPaidAd,
};

const setTrackingCategory = (
  isPaidAd: boolean,
  isBrandAffiliate: boolean,
  pageType: AdMarqueePageLocation,
) => {
  if (isBrandAffiliate) {
    return brandAffiliateCategories[pageType];
  }

  return isPaidAd ? paidCategories[pageType] : houseCategories[pageType];
};

export default setTrackingCategory;
