import "@splidejs/react-splide/css";

import React from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";

import {
  AdMarqueePageLocation,
  AdMarqueeType,
  Creative,
} from "custom-types/AdMarquee";
import useFetchMarqueeCreatives from "hooks/useFetchMarqueeCreatives";
import setTrackingCategory from "utils/marqueeAd/setTrackingCategory";

import MarqueeAdCard from "components/AdMarqueeCard/MarqueeAd";
import ChevronIcon from "components/Icons/ChevronIcon";
import Spinner from "components/Icons/Spinner";

type Props = {
  allowAutoplay?: boolean;
  autoplayInterval?: number;
  creatives?: Creative[];
  pageType: AdMarqueePageLocation;
};

const AdMarqueeCarousel: React.FC<Props> = ({
  allowAutoplay = true,
  autoplayInterval = 5000,
  creatives: initialCreatives,
  pageType,
}) => {
  const { creatives, loading: loadingMarqueeCreatives } =
    useFetchMarqueeCreatives(pageType, initialCreatives);

  return (
    <>
      <Splide
        className="relative w-full"
        id="ad-marquee-carousel"
        data-testid="ad-marquee-carousel"
        aria-label="Advertising Carousel"
        options={{
          autoplay: allowAutoplay,
          interval: autoplayInterval,
          rewind: true,
        }}
        hasTrack={false}
      >
        <SplideTrack className="aspect-[343/215] md:aspect-[992/207]">
          {loadingMarqueeCreatives ? (
            <div className="w-full h-full flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            creatives.map((ad, index) => {
              const isDispensaryAd =
                ad.creativeType === AdMarqueeType.DISPENSARY;
              const isBrandAffiliate =
                ad.creativeType === AdMarqueeType.BRAND_AFFILIATE;
              const trackingCategory = setTrackingCategory(
                isDispensaryAd,
                isBrandAffiliate,
                pageType,
              );

              return (
                <SplideSlide key={`creative-${index}`}>
                  <MarqueeAdCard
                    ad={ad}
                    hasInitialMarquees={!!initialCreatives?.length}
                    slot={index}
                    trackingCategory={trackingCategory}
                  />
                </SplideSlide>
              );
            })
          )}
        </SplideTrack>

        {/*
            Details for custom pagination: https://splidejs.com/integration/react-splide/#custom-structure
            react-splide allows you to seperate the arrows/pagination from the track by using hasTrack={false}
            and HTML placeholders. The below HTML simply defines the structure of the arrows/pagination and
            defines custom arrows.

            Note that splide tries to apply its own `transform` rule to the SVGs in these buttons, so we have
            to add a `!transform` class to the SVGs to ensure that tailwind transformations work as expected.
          */}
        <div className="splide__arrows min-h-[24px]">
          <button className="splide__arrow splide__arrow--prev">
            <ChevronIcon className="!transform" direction="left" height="12" />
          </button>
          <ul className="splide__pagination">
            <li></li>
          </ul>
          <button className="splide__arrow splide__arrow--next">
            <ChevronIcon className="!transform" direction="right" height="12" />
          </button>
        </div>
      </Splide>

      <style jsx global>{`
        #ad-marquee-carousel :global(.splide__arrows) {
          margin-top: 1em;
          display: flex;
          justify-content: center;
          align-items: center;
          position: static;
        }
        #ad-marquee-carousel :global(.splide__arrows) :global(.splide__arrow) {
          position: static;
          transform: unset;
          height: 1.5em;
          width: 1.5em;
          background-color: #ffffff;
          box-shadow:
            0 4px 6px -1px rgb(0 0 0 / 0.1),
            0 2px 4px -2px rgb(0 0 0 / 0.1);
        }
        #ad-marquee-carousel :global(.splide__pagination) {
          position: static;
          display: flex;
          align-items: center;
        }
        #ad-marquee-carousel :global(.splide__pagination__page.is-active) {
          transform: scale(1);
          background-color: #333333;
          opacity: 1;
        }
        #ad-marquee-carousel :global(.splide__pagination__page) {
          transform: scale(1);
          height: 8px;
          width: 8px;
          opacity: 0.3;
          transition: background-color 420ms ease;
          background-color: #333333;
          margin: 4px;
        }
        .splide__track.invisible.block .splide__list {
          display: block;
        }
      `}</style>
    </>
  );
};

export default AdMarqueeCarousel;
