import React from "react";
import classNames from "classnames";
import Link from "next/link";

import { Action } from "constants/events";
import {
  AdMarqueeType,
  BACKGROUND_IMAGE_DESKTOP_WIDTH,
  BACKGROUND_IMAGE_MOBILE_WIDTH,
  Creative,
} from "custom-types/AdMarquee";
import { useEventTracker } from "hooks/useEventTracker";
import isHexColorBright from "utils/isHexColorBright";

import AdFlag from "components/botanic/AdFlag";
import Button from "components/botanic/Button";
import Image from "components/Image";
import TrackImpression from "components/TrackImpression";

const MarqueeText = ({
  topLine,
  headline,
  textColor,
}: {
  topLine: string | null;
  headline: string | null;
  textColor: string | null;
}) => {
  const textStyle = {
    color: textColor || "",
  };

  return (
    <div className="pl-4 md:pl-16 lg:pl-20 font-extrabold flex flex-col justify-end w-full">
      <p className="mb-0 text-xs" style={textStyle}>
        {topLine}
      </p>
      <p className="mb-0 text-md" style={textStyle}>
        {headline}
      </p>
    </div>
  );
};

const MarqueeButton = ({
  buttonColor,
  buttonTextColor,
  buttonText,
}: {
  buttonColor: string | null;
  buttonTextColor: string | null;
  buttonText: string | null;
}) => (
  <div className="pl-4 md:pl-16 lg:pl-20 w-fit">
    <Button
      className="min-w-[10rem]"
      style={{
        backgroundColor: buttonColor || undefined,
        color: buttonTextColor || undefined,
      }}
    >
      {buttonText || "Shop Now"}
    </Button>
  </div>
);

export const AdvancedMarqueeImage = ({
  desktopImage,
  mobileImage,
  preload,
}: {
  desktopImage: string;
  mobileImage: string;
  preload?: boolean;
}) => (
  <Image
    alt=""
    background
    backgroundFit="contain"
    className="z-0"
    disableLazyLoad
    mobileSrc={mobileImage}
    preload={preload}
    sizes={[
      BACKGROUND_IMAGE_MOBILE_WIDTH,
      null,
      BACKGROUND_IMAGE_DESKTOP_WIDTH,
      null,
      null,
      BACKGROUND_IMAGE_DESKTOP_WIDTH,
    ]}
    src={desktopImage}
  />
);

const BasicMarqueeImage = ({
  imageUrl,
  preload,
}: {
  imageUrl: string;
  preload?: boolean;
}) => (
  <Image
    alt=""
    className="h-full flex justify-end items-end"
    disableLazyLoad
    preload={preload}
    sizes={[192, 254, 182, 245, null, 240]}
    src={imageUrl}
  />
);

export const MarqueeAdCard = ({
  ad,
  hasInitialMarquees,
  slot,
  trackingCategory,
}: {
  ad: Creative;
  hasInitialMarquees?: boolean;
  slot: number;
  trackingCategory: string;
}) => {
  const { publishEvent } = useEventTracker();

  const {
    backgroundColor,
    dispensaryId,
    brandId,
    merchandisingCampaignId,
    destinationUrl,
    creativeType,
  } = ad;

  const isAdvancedMarquee = !!(
    ad.backgroundImageDesktop && ad.backgroundImageMobile
  );

  const brightnessCheck = !!(
    !isAdvancedMarquee &&
    backgroundColor &&
    isHexColorBright(backgroundColor, 0.85)
  );

  const cardClassNames = classNames(
    "rounded overflow-hidden relative h-full w-full",
    {
      "border border-light-grey": brightnessCheck,
    },
  );

  const isNotHouseAd = [
    AdMarqueeType.DISPENSARY,
    AdMarqueeType.BRAND,
    AdMarqueeType.BRAND_AFFILIATE,
  ].includes(creativeType);

  return (
    <TrackImpression
      className={cardClassNames}
      style={{ backgroundColor: backgroundColor || "" }}
      data-testid="ad-card-container"
      trackingFunction={() => {
        publishEvent({
          action: Action.impression,
          ...(isNotHouseAd && {
            brandId: brandId,
            dispensaryId: dispensaryId,
            merchandisingCampaignId: merchandisingCampaignId,
          }),
          category: trackingCategory,
          label: `slot #${slot + 1}`,
        });
      }}
    >
      <Link
        href={destinationUrl}
        rel={isNotHouseAd ? "sponsored" : ""}
        className="grid grid-cols-[5fr_3fr] md:grid-cols-[1fr_254px] grid-rows-[1fr] md:grid-rows-1 overflow-hidden h-full w-full"
        onClick={() => {
          publishEvent({
            action: Action.click,
            ...(isNotHouseAd && {
              brandId: brandId,
              dispensaryId: dispensaryId,
              merchandisingCampaignId: merchandisingCampaignId,
            }),
            category: trackingCategory,
            label: `slot #${slot + 1}`,
          });
        }}
      >
        <div className="flex flex-col gap-2 justify-center lg:gap-4 relative z-[1]">
          <MarqueeText
            topLine={ad.topLine}
            headline={ad.headline}
            textColor={ad.textColor}
          />
          <MarqueeButton
            buttonText={ad.buttonText}
            buttonColor={ad.buttonColor}
            buttonTextColor={ad.buttonTextColor}
          />
        </div>
        {isAdvancedMarquee ? (
          <AdvancedMarqueeImage
            desktopImage={ad.backgroundImageDesktop as string}
            mobileImage={ad.backgroundImageMobile as string}
            preload={hasInitialMarquees}
          />
        ) : (
          ad.imageUrl && (
            <BasicMarqueeImage
              imageUrl={ad.imageUrl}
              preload={hasInitialMarquees}
            />
          )
        )}
        <AdFlag promoted={isNotHouseAd} position="right" />
      </Link>
    </TrackImpression>
  );
};

export default MarqueeAdCard;
