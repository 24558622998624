export enum AdMarqueePageLocation {
  HOME_PAGE = "homepage",
  STRAINS_LIST_PAGE = "strains_lists",
  DEALS_PAGE = "deals_page",
}

export enum AdMarqueeType {
  HOUSE = "house",
  DISPENSARY = "dispensary",
  BRAND = "brand",
  BRAND_AFFILIATE = "brand-affiliate",
}

export type Creative = {
  backgroundColor: string | null;
  backgroundImageDesktop: string | null;
  backgroundImageMobile: string | null;
  buttonColor: string;
  buttonText: string | null;
  buttonTextColor: string | null;
  creativeType: AdMarqueeType;
  destinationUrl: string;
  dispensaryId: number | null;
  brandId: number | null;
  headline: string;
  imageUrl: string | null;
  merchandisingCampaignId: number | null;
  textColor: string | null;
  topLine: string | null;
};

// While we do store these in variables for reuse, there are some
// areas where we use these values directly in the code, like in
// the AdMarqueeCarousel component. This is because these values
// are used to set the aspect ratio of the carousel using tailwind
// arbitary values.
export const BACKGROUND_IMAGE_DESKTOP_WIDTH = 992;
export const BACKGROUND_IMAGE_DESKTOP_HEIGHT = 207;
export const BACKGROUND_IMAGE_MOBILE_WIDTH = 343;
export const BACKGROUND_IMAGE_MOBILE_HEIGHT = 215;
